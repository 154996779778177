import React, { useEffect, useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryStringResults } from '@plone/volto/actions';

import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useIntl, defineMessages, injectIntl } from 'react-intl';
import { MailTo } from '@package/helpers';
// import { ContactForm } from '@package/components';

import mailSVG from '@package/icons/mail-blue.svg';
import phoneSVG from '@package/icons/phone.svg';
import mapSVG from '@package/icons/map.svg';
import avatarPlaceholderGreyBackgroundSVG from '@package/icons/fallback-avatar-grey-background.svg';

const messages = defineMessages({
  showMembers: {
    id: 'Show All Members',
    defaultMessage: 'Show All Members',
  },
  hideMembers: {
    id: 'Hide Members',
    defaultMessage: 'Hide Members',
  },
  room: {
    id: 'Room',
    defaultMessage: 'Room',
  },
  building: {
    id: 'Building',
    defaultMessage: 'Building',
  },
  pleaseEnter: {
    id: 'Please enter',
    defaultMessage: 'Please enter',
  },
});

const HiERNMemberView = (props) => {
  const path = props.path;
  const block = props.id;
  const query = props.data ? props.data.researchGroup ?? '' : '';
  const sort_order = props.data
    ? props.data.sort_order_boolean
      ? 'descending'
      : 'ascending'
    : 'ascending';

  const dispatch = useDispatch();
  const querystringResults = useSelector(
    (state) => state.querystringsearch.subrequests,
  );
  const [isShowMore, setShowMore] = useState(false);
  const intl = useIntl();

  let WithoutImage = false;
  let initialHeight = 395;
  let defaultNoOfMembers = 3;
  if (props.data.variation) {
    WithoutImage = props.data.variation === 'Without Image';
    if (WithoutImage) {
      initialHeight = 540;
      defaultNoOfMembers = 4;
    }
  }

  const [height, setHeight] = useState(initialHeight);

  // const [showContactForm, setShowContactForm] = useState(false);
  // const [currentContact, setCurrentContact] = useState(false);

  useEffect(() => {
    if (props.data.variation === 'Without Image') {
      setHeight(540);
    } else {
      setHeight(395);
    }
  }, [props.data.variation]);

  const onClickHandler = () => {
    if (!isShowMore) {
      setHeight('auto');
    } else {
      setHeight(initialHeight);
    }
    setShowMore(!isShowMore);
  };

  useEffect(() => {
    dispatch(
      getQueryStringResults(
        '',
        {
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['Member'],
            },
            {
              i: 'research_group',
              o: 'plone.app.querystring.operation.selection.any',
              v: [query],
            },
          ],
          metadata_fields: '_all',
          sort_order,
          sort_on: 'name',
          b_size: 50,
        },
        block,
      ),
    );
  }, [path, block, dispatch, query, sort_order]);

  let listingItems = [];
  let querystringLength = 0;
  if (querystringResults?.[block]) {
    querystringLength = querystringResults?.[block]?.items.length;
    listingItems = querystringResults?.[block]?.items;
  }

  return (
    <>
      {/* {showContactForm && (
        <ContactForm
          {...props}
          data={currentContact}
          onClose={() => setShowContactForm(false)}
          memberURL={flattenToAppURL(currentContact['@id'])}
        />
      )} */}
      <div className="members-view">
        <div>
          <h2
            className={cx('headline', {
              'without-image': WithoutImage,
            })}
          >
            {props.data.title}
          </h2>
        </div>
        <AnimateHeight
          className={cx('', { 'without-image': WithoutImage })}
          style={{ flexShrink: 0 }}
          height={height}
          duration={500}
        >
          <Grid stackable columns={WithoutImage ? 2 : 3}>
            <Grid.Row>
              {listingItems &&
                listingItems.map((item) => {
                  if (WithoutImage) {
                    return (
                      <Grid.Column key={item.id} className="grid-column ">
                        <div className="without-image">
                          <div className="content">
                            <div>
                              <UniversalLink href={item['@id']}>
                                <h2>
                                  {`${item.academic ?? ''} ${
                                    item.firstname ?? ''
                                  } ${item.name ?? ''}`}
                                </h2>
                              </UniversalLink>
                              <p>{item.positions ?? ''}</p>
                            </div>
                            {item.building && (
                              <div className="building">
                                <Icon
                                  name={mapSVG}
                                  size="24px"
                                  color="#023d6b"
                                ></Icon>
                                <span>
                                  {`${intl.formatMessage(messages.building)} ${
                                    item.building
                                  } / ${intl.formatMessage(messages.room)} ${
                                    item.room ?? ''
                                  }`}
                                </span>
                              </div>
                            )}
                            {item.phone && (
                              <div className="phone">
                                <Icon
                                  name={phoneSVG}
                                  color="#023d6b"
                                  size="20px"
                                ></Icon>
                                <span className="phone">{item.phone}</span>
                              </div>
                            )}
                            {item.email && (
                              <div className="mail">
                                <Icon name={mailSVG} size="14px"></Icon>
                                <MailTo email={item.email} className="mail" />
                                {/* <span
                                  onClick={() => {
                                    setShowContactForm(true);
                                    setCurrentContact(item);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      setShowContactForm(true);
                                      setCurrentContact(item);
                                    }
                                  }}
                                  className="mail"
                                  role="button"
                                  tabIndex={0}
                                >
                                  E-Mail
                                </span> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  } else {
                    return (
                      <Grid.Column key={item.id} className="grid-column">
                        <div>
                          <div className="image-container">
                            <UniversalLink
                              title={item.firstname + ' ' + item.name}
                              href={item['@id']}
                              tabIndex={-1}
                            >
                              {item?.image_field ? (
                                <Image
                                  src={flattenToAppURL(
                                    `${item['@id']}/@@images/${item.image_field}/preview`,
                                  )}
                                  size="small"
                                  avatar
                                  alt=""
                                />
                              ) : (
                                <Icon
                                  name={avatarPlaceholderGreyBackgroundSVG}
                                  size="150px"
                                  className="placeholder"
                                />
                              )}
                            </UniversalLink>
                          </div>
                          <div className="content">
                            <div>
                              <UniversalLink href={item['@id']}>
                                <h2>{`${item.academic ?? ''} ${
                                  item.firstname ?? ''
                                } ${item.name ?? ''}`}</h2>
                              </UniversalLink>
                              <p>{item.positions ?? ''}</p>
                            </div>
                            {item.building && (
                              <span className="building">{`${intl.formatMessage(
                                messages.building,
                              )} ${item.building} / ${intl.formatMessage(
                                messages.room,
                              )} ${item.room ?? ''}`}</span>
                            )}
                            {item.phone && (
                              <span className="phone">{item.phone}</span>
                            )}
                            {/* {item.email && (
                              <span
                                onClick={() => {
                                  setShowContactForm(true);
                                  setCurrentContact(item);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setShowContactForm(true);
                                    setCurrentContact(item);
                                  }
                                }}
                                className="mail"
                                role="button"
                                tabIndex={0}
                              >
                                E-Mail
                              </span>
                            )} */}
                          </div>
                        </div>
                      </Grid.Column>
                    );
                  }
                })}
            </Grid.Row>
          </Grid>
        </AnimateHeight>

        {querystringLength > defaultNoOfMembers && (
          <div
            className={cx('button-container', {
              expand: !isShowMore,
              'without-image': WithoutImage,
            })}
          >
            <button onClick={onClickHandler}>
              {isShowMore
                ? intl.formatMessage(messages.hideMembers)
                : intl.formatMessage(messages.showMembers)}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default injectIntl(HiERNMemberView);
