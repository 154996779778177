import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { SchemaRenderer } from '@kitconcept/volto-blocks/components';
import { TeaserWithLinkSchema } from './schema';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const TeaserData = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();

  const href = data.href?.[0];
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (href && !data.title && !data.description) {
      // if target is an internal site, use the api path instead.
      const targetURL = isInternalURL(href['@id'])
        ? flattenToAppURL(href['@id'])
        : href['@id'];
      dispatch(getContent(targetURL, null, block)).then((resp) => {
        onChangeBlock(block, {
          ...data,
          ...(!data.title && { title: resp.title }),
          ...(!data.description && { description: resp.description }),
        });
      });
    }
    if (!href) {
      onChangeBlock(block, {
        ...data,
        title: '',
        description: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href]);

  const schema = TeaserWithLinkSchema({ ...props, intl });

  const applySchemaEnhancer = (schema) => {
    const variations =
      config.blocks?.blocksConfig['teaserWithLink']?.variations;

    const schemaExtender = variations?.[data.variation]?.['schemaExtenderItem'];

    if (schemaExtender) {
      return schemaExtender(schema, props, intl);
    } else {
      return schema;
    }
  };

  return (
    <SchemaRenderer
      schema={applySchemaEnhancer(schema)}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
    />
  );
};

export default TeaserData;
