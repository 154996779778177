import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { BlockDataForm } from '@plone/volto/components';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';

import { HighlightTeaserSchema } from './schema';

const HighlightTeaserData = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();

  const href = data.href?.[0];
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (href && !data.title && !data.description) {
      // if target is an internal site, use the api path instead
      const targetURL = isInternalURL(href['@id'])
        ? flattenToAppURL(href['@id'])
        : href['@id'];

      dispatch(getContent(targetURL, null, block)).then((resp) => {
        onChangeBlock(block, {
          ...data,
          ...(!data.title && { title: resp.title }),
          ...(!data.description && { description: resp.description }),
        });
      });
    }
    if (!href) {
      onChangeBlock(block, {
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href]);

  const schema = HighlightTeaserSchema({ ...props, intl });

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      block={block}
    />
  );
};

export default HighlightTeaserData;
