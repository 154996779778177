/**
 * Routes.
 * @module routes
 */

import { App, Search } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import {
  CentralProfile,
  UnlockFormView,
  CartBasket,
  CartCheckout,
} from './components';
import { RequestPasswordReset, PasswordReset } from '@plone/volto/components';
import LinksToItem from './components/LinksToItem/LinksToItem';

/**
 *
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      // Add your routes here
      {
        path: '/**/@cart',
        exact: true,
        component: CartBasket,
      },
      {
        path: '/@cart-checkout',
        exact: false,
        component: CartCheckout,
      },
      {
        path: '/profil',
        exact: true,
        component: CentralProfile,
      },
      {
        path: `/(${config.settings?.supportedLanguages.join('|')})/search`,
        component: config.widgets.SolrSearch,
      },
      {
        // Use @@ prefix to discriminate from content.
        path: `/@@search`,
        component: config.widgets.SolrSearch,
      },
      {
        // Use @@ prefix to discriminate from content.
        path: `/**/@@search`,
        component: config.widgets.SolrSearch,
      },
      {
        path: `/search`,
        component: config.widgets.SolrSearch,
      },
      {
        path: `/(${config.settings?.supportedLanguages.join('|')})/search-old`,
        component: Search,
      },
      {
        path: `/search-old`,
        component: Search,
      },
      {
        path: `/(${config.settings?.supportedLanguages.join(
          '|',
        )})/**/unlock-form`,
        component: UnlockFormView,
      },
      {
        path: '/links-to-item',
        component: LinksToItem,
      },
      {
        path: '/**/links-to-item',
        component: LinksToItem,
      },
      {
        path: `/(${config.settings?.supportedLanguages.join(
          '|',
        )})/passwordreset`,
        component: RequestPasswordReset,
        exact: true,
      },
      {
        path: `/(${config.settings?.supportedLanguages.join(
          '|',
        )})/passwordreset/:token`,
        component: PasswordReset,
        exact: true,
      },
      {
        path: '/passwordreset',
        component: RequestPasswordReset,
        exact: true,
      },
      {
        path: '/passwordreset/:token',
        component: PasswordReset,
        exact: true,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
