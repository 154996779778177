import { defineMessages } from 'react-intl';
import BlogPost from './components/View/BlogPost';

defineMessages({
  documents: {
    id: 'Documents',
    defaultMessage: 'Documents',
  },
  images: {
    id: 'Images',
    defaultMessage: 'Images',
  },
  scrollTop: {
    id: 'scroll to top',
    defaultMessage: 'scroll to top',
  },
  about: {
    id: 'About',
    defaultMessage: 'About',
  },
  leaveareply: {
    id: 'Leave a Reply',
    defaultMessage: 'Leave a Reply',
  },
});

const applyConfig = (config) => {
  config.views.contentTypesViews.Post = BlogPost;
  return config;
};

export default applyConfig;
