import React from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { Doormat, MobileNavigation } from '@package/components';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import clearSVG from '@plone/volto/icons/clear.svg';
import zoomSVG from '@package/icons/search-icon.svg';

import { flattenToAppURL } from '@plone/volto/helpers';
import { Logo, Navigation, LanguageSelector } from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  institute: {
    id: 'Institute',
    defaultMessage: 'Institute',
  },
});

const MainHeader = (props) => {
  const { pathname, blogs } = props;
  const [showSitemap, setShowSitemap] = React.useState(false);
  const intl = useIntl();
  const history = useHistory();

  const rootPathname = flattenToAppURL(blogs?.['@id']);
  const searchUrl =
    rootPathname.replace(/\/$/, '').replace(/\/$/, '') +
    '/@@search?allow_local=true&local=true';

  const modalHandler = () => {
    setShowSitemap(!showSitemap);
  };

  return (
    <Segment basic className="header-wrapper institut" role="banner">
      <Container>
        <div className="header">
          <div className="header-top">
            <LanguageSelector />
          </div>
          <div className="logo-nav-wrapper">
            <div className="logo">
              <Logo />

              {/* <div className="blogs-title">
                <Link to={flattenToAppURL(bannerImage['@id'])}>
                  <h1>{bannerImage.name}</h1>
                </Link>
              </div> */}
              <div className="blogs-title">
                <Link to={flattenToAppURL(blogs['@id'])}>
                  <h1>{blogs.name}</h1>
                </Link>
              </div>
            </div>
            <MobileNavigation />
            <Link
              to={searchUrl}
              className="search mobile-nav mobile only tablet only"
              title="Search"
            >
              <Button
                className="institut-search"
                basic
                icon
                aria-label={intl.formatMessage(messages.search)}
              >
                <Icon name={zoomSVG} size="36px" />
              </Button>
            </Link>
          </div>
          <div className="blogs-nav">
            <Navigation pathname={pathname} />
            <Button
              className="institut-search computer large screen widescreen only"
              basic
              icon
              aria-label={intl.formatMessage(messages.search)}
              onClick={() => history.push(searchUrl)}
            >
              <Icon name={zoomSVG} size="36px" />
            </Button>
          </div>
          {showSitemap && (
            <div>
              <button onClick={modalHandler} className="close-icon">
                <Icon name={clearSVG} size="32px" color="#fff" />
              </button>
              <div
                role="button"
                className="modal"
                onKeyDown={modalHandler}
                onClick={modalHandler}
                tabIndex={0}
              >
                <Doormat columns="3" />
              </div>
            </div>
          )}
        </div>
      </Container>
    </Segment>
  );
};

export default MainHeader;
