import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink, Icon } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Grid } from 'semantic-ui-react';
import dummyImage from '@package/icons/dummy-image.png';
import { EffectiveDate } from '@kitconcept/volto-blocks/components';
import TagSvg from '@plone/volto/icons/tag.svg';

const GridListingTwoColumns = ({ items, linkMore, isEditMode }) => {
  return (
    <>
      <div className="grid-listing-twocolumns blog-post-grid">
        <div className="items">
          <Grid>
            <Grid.Row columns={12}>
              {items.map((item) => (
                <Grid.Column
                  computer={6}
                  tablet={6}
                  width={12}
                  className="container-column"
                >
                  <div key={item.UID} className="event-item">
                    <div className="content">
                      <div className="listing-item" key={item['@id']}>
                        <div className="text">
                          <ConditionalLink
                            to={flattenToAppURL(item['@id'])}
                            condition={!isEditMode}
                          >
                            <div className="grid-image-wrapper">
                              <img
                                src={
                                  item.image_field
                                    ? flattenToAppURL(
                                        `${item['@id']}/@@images/${item.image_field}/preview`,
                                      )
                                    : dummyImage
                                }
                                alt={item.title}
                              />
                            </div>
                          </ConditionalLink>
                          <div className="listing-body">
                            <div className="dates">
                              <span className="day">
                                <EffectiveDate item={item} />{' '}
                              </span>
                              {item?.post_authors?.length > 0 && (
                                <span className="head-title">
                                  {item?.post_authors?.map(
                                    (author, index, authors) => (
                                      <>
                                        <UniversalLink href={author.url}>
                                          {author.title}
                                        </UniversalLink>
                                        {index !== authors.length - 1 && (
                                          <>,&nbsp;</>
                                        )}
                                      </>
                                    ),
                                  )}
                                  &nbsp;
                                </span>
                              )}
                              {item?.blog && (
                                <span className="head-title">
                                  <UniversalLink href={item.blog.url}>
                                    {item.blog.title}
                                  </UniversalLink>
                                </span>
                              )}
                            </div>
                          </div>

                          <ConditionalLink
                            to={flattenToAppURL(item['@id'])}
                            condition={!isEditMode}
                          >
                            <div className="listing-body">
                              <h2>{item.title ? item.title : item.id}</h2>
                              <p className="description">{item?.description}</p>
                            </div>
                          </ConditionalLink>

                          <span className="tags-container">
                            {item?.post_tags?.length > 0 && (
                              <>
                                <span className="tag-icon">
                                  <Icon name={TagSvg} size={15} />
                                </span>
                                {item?.post_tags?.map(
                                  (tag, index, postTags) => (
                                    <span className="tag-item">
                                      <UniversalLink href={tag.url}>
                                        {tag.title}
                                      </UniversalLink>
                                      {index !== postTags.length - 1 && (
                                        <>,&nbsp;</>
                                      )}
                                    </span>
                                  ),
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </>
  );
};
GridListingTwoColumns.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default GridListingTwoColumns;
