import React, { useEffect } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { map } from 'lodash';
import config from '@plone/volto/registry';
import { EffectiveDate } from '@kitconcept/volto-blocks/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthors } from '@package/actions/';
import { Image } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';

import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  getBaseUrl,
} from '@plone/volto/helpers';

const messages = defineMessages({
  unknownBlock: {
    id: 'Unknown Block',
    defaultMessage: 'Unknown Block {block}',
  },
});

const BlogPostView = ({ content, intl, location }) => {
  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);

  const dispatch = useDispatch();

  const pathname = location.pathname;
  useEffect(() => {
    dispatch(getAuthors(pathname));
  }, [dispatch, pathname]);
  const authors = useSelector((state) => state.authors);

  return (
    <div id="page-document" className="ui container">
      <div className="dates">
        <span className="day">
          <EffectiveDate item={content} />{' '}
        </span>
        {content.post_authors.length > 0 && (
          <span className="head-title">
            {content?.post_authors?.map((author, index, postAuthors) => (
              <span>
                <UniversalLink href={author.url}>{author.title}</UniversalLink>
                {index !== postAuthors.length - 1 && <>,&nbsp;</>}
              </span>
            ))}
          </span>
        )}
      </div>
      {map(content[blocksLayoutFieldname].items, (block) => {
        const Block =
          config.blocks.blocksConfig[
            content[blocksFieldname]?.[block]?.['@type']
          ]?.['view'] || null;
        return Block !== null ? (
          <Block
            key={block}
            id={block}
            properties={content}
            data={content[blocksFieldname][block]}
            path={getBaseUrl(location?.pathname || '')}
          />
        ) : (
          <div key={block}>
            {intl.formatMessage(messages.unknownBlock, {
              block: content[blocksFieldname]?.[block]?.['@type'],
            })}
          </div>
        );
      })}
      <span className="tags-container">
        {content?.post_tags?.length > 0 && (
          <>
            <strong>
              <FormattedMessage id="BlogTags" defaultMessage="Tags:" />
            </strong>
            &nbsp;
            {content?.post_tags?.map((tag, index, postTags) => (
              <span className="tag-item">
                <UniversalLink href={tag.url}>{tag.title}</UniversalLink>
                {index !== postTags.length - 1 && <>,&nbsp;</>}
              </span>
            ))}
          </>
        )}
      </span>
      <div className="blog-sharing">
        <ul>
          <li class="" style={{ backgroundColor: '#1488bf' }}>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${
                config.settings.publicURL
              }${flattenToAppURL(content['@id'])}`}
              title="Bei LinkedIn teilen"
              aria-label="Bei LinkedIn teilen"
              role="button"
              rel="noreferrer noopener nofollow"
              style={{ backgroundColor: '#0077b5', color: '#fff' }}
              target="_blank"
            >
              <span>
                <svg
                  width="32px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 27 32"
                >
                  <path
                    fill="#fff"
                    d="M6.2 11.2v17.7h-5.9v-17.7h5.9zM6.6 5.7q0 1.3-0.9 2.2t-2.4 0.9h0q-1.5 0-2.4-0.9t-0.9-2.2 0.9-2.2 2.4-0.9 2.4 0.9 0.9 2.2zM27.4 18.7v10.1h-5.9v-9.5q0-1.9-0.7-2.9t-2.3-1.1q-1.1 0-1.9 0.6t-1.2 1.5q-0.2 0.5-0.2 1.4v9.9h-5.9q0-7.1 0-11.6t0-5.3l0-0.9h5.9v2.6h0q0.4-0.6 0.7-1t1-0.9 1.6-0.8 2-0.3q3 0 4.9 2t1.9 6z"
                  ></path>
                </svg>
              </span>
            </a>
          </li>
          <li class="" style={{ backgroundColor: '#a8a8a8' }}>
            <a
              href={`mailto:?body=${config.settings.publicURL}${flattenToAppURL(
                content['@id'],
              )}&subject=${content.title}`}
              title="Per E-Mail versenden"
              aria-label="Per E-Mail versenden"
              role="button"
              rel="noreferrer noopener nofollow"
              style={{ backgroundColor: '#999', color: '#fff' }}
            >
              <span>
                <svg
                  width="32px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#fff"
                    d="M32 12.7v14.2q0 1.2-0.8 2t-2 0.9h-26.3q-1.2 0-2-0.9t-0.8-2v-14.2q0.8 0.9 1.8 1.6 6.5 4.4 8.9 6.1 1 0.8 1.6 1.2t1.7 0.9 2 0.4h0.1q0.9 0 2-0.4t1.7-0.9 1.6-1.2q3-2.2 8.9-6.1 1-0.7 1.8-1.6zM32 7.4q0 1.4-0.9 2.7t-2.2 2.2q-6.7 4.7-8.4 5.8-0.2 0.1-0.7 0.5t-1 0.7-0.9 0.6-1.1 0.5-0.9 0.2h-0.1q-0.4 0-0.9-0.2t-1.1-0.5-0.9-0.6-1-0.7-0.7-0.5q-1.6-1.1-4.7-3.2t-3.6-2.6q-1.1-0.7-2.1-2t-1-2.5q0-1.4 0.7-2.3t2.1-0.9h26.3q1.2 0 2 0.8t0.9 2z"
                  ></path>
                </svg>
              </span>
            </a>
          </li>
          <li className="whatsapp" style={{ backgroundColor: '#5cbe4a' }}>
            <a
              href={`https://api.whatsapp.com/send?text=${
                config.settings.publicURL
              }${flattenToAppURL(content['@id'])}`}
              title="Bei Whatsapp teilen"
              aria-label="Bei Whatsapp teilen"
              role="button"
              rel="noreferrer noopener nofollow"
              style={{ backgroundColor: '#34af23', color: '#fff' }}
              target="_blank"
            >
              <span>
                <svg
                  width="32px"
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#fff"
                    d="M17.6 17.4q0.2 0 1.7 0.8t1.6 0.9q0 0.1 0 0.3 0 0.6-0.3 1.4-0.3 0.7-1.3 1.2t-1.8 0.5q-1 0-3.4-1.1-1.7-0.8-3-2.1t-2.6-3.3q-1.3-1.9-1.3-3.5v-0.1q0.1-1.6 1.3-2.8 0.4-0.4 0.9-0.4 0.1 0 0.3 0t0.3 0q0.3 0 0.5 0.1t0.3 0.5q0.1 0.4 0.6 1.6t0.4 1.3q0 0.4-0.6 1t-0.6 0.8q0 0.1 0.1 0.3 0.6 1.3 1.8 2.4 1 0.9 2.7 1.8 0.2 0.1 0.4 0.1 0.3 0 1-0.9t0.9-0.9zM14 26.9q2.3 0 4.3-0.9t3.6-2.4 2.4-3.6 0.9-4.3-0.9-4.3-2.4-3.6-3.6-2.4-4.3-0.9-4.3 0.9-3.6 2.4-2.4 3.6-0.9 4.3q0 3.6 2.1 6.6l-1.4 4.2 4.3-1.4q2.8 1.9 6.2 1.9zM14 2.2q2.7 0 5.2 1.1t4.3 2.9 2.9 4.3 1.1 5.2-1.1 5.2-2.9 4.3-4.3 2.9-5.2 1.1q-3.5 0-6.5-1.7l-7.4 2.4 2.4-7.2q-1.9-3.2-1.9-6.9 0-2.7 1.1-5.2t2.9-4.3 4.3-2.9 5.2-1.1z"
                  ></path>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </div>
      {authors?.items?.map(
        (author) =>
          author['@type'] === 'Author' && (
            <div key={author['@id']}>
              <div className="about-the-author">
                <figure className="author-profile">
                  {author?.image_field ? (
                    <Image
                      src={flattenToAppURL(
                        `${author['@id']}/@@images/${author.image_field}/preview`,
                      )}
                      alt={author.fullname}
                      className="headshot"
                    />
                  ) : null}
                  <div>
                    <figcaption>
                      <h2 className="heading">
                        <FormattedMessage id="About" defaultMessage="About" />
                        {` ${author?.fullname}`}
                      </h2>
                      <p className="description">{`${author?.description} `}</p>
                    </figcaption>
                    <UniversalLink href={author['@id']}>
                      <button className="ui button">
                        <FormattedMessage id="More" defaultMessage="More" />
                      </button>
                    </UniversalLink>
                  </div>
                </figure>
              </div>
            </div>
          ),
      )}
    </div>
  );
};

export default injectIntl(BlogPostView);
