import React, { useEffect, useState, useCallback } from 'react';
import { Container, Input, Button, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import VocabularyMultiSelect from './Job-Listing/VocabularyMultiSelect';
import { defineMessages, injectIntl } from 'react-intl';

import { Icon } from '@plone/volto/components';
import { jobOfferSearchContent } from '../../actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import upArrowSVG from '@plone/volto/icons/up.svg';
import downArrowSVG from '@plone/volto/icons/down.svg';

const useQuery = () => new URLSearchParams(useLocation().search);

const messages = defineMessages({
  Keywordsearch: {
    id: 'Keyword search',
    defaultMessage: 'Keyword search',
  },
  Targetgroup: {
    id: 'Career level',
    defaultMessage: 'Career level',
  },
  Location: {
    id: 'Place of work',
    defaultMessage: 'Place of work',
  },
  JobSubject: {
    id: 'Discipline',
    defaultMessage: 'Discipline',
  },
  MoreJobs: {
    id: 'More Jobs',
    defaultMessage: 'More Jobs',
  },
  JobID: {
    id: 'Job ID',
    defaultMessage: 'Job ID',
  },
  JobTitle: {
    id: 'Job title',
    defaultMessage: 'Job title',
  },
  Institut: {
    id: 'Institut',
    defaultMessage: 'Institute',
  },
  JobFoW: {
    id: 'Field of work',
    defaultMessage: 'Field of work',
  },
  ResultsFound: {
    id: 'results found',
    defaultMessage: 'results found',
  },
  ResetFilter: {
    id: 'Reset Filter',
    defaultMessage: 'Reset Filter',
  },
});

const B_SIZE = 200;

const JobListingView = (props) => {
  const searchFormFields = [
    'text',
    'targetGroup',
    'location',
    'categories',
    'fieldOfWork',
  ];
  const { content, location, history } = props;
  const params = useQuery();
  const dispatch = useDispatch();

  /* Following States are only to manage the markup of filter settings in the Filter UI*/
  const [searchText, setSearchText] = useState(params.get('text') || '');
  const [searchTextInput, setSearchTextInput] = useState(
    params.get('text') || '',
  );
  const [searchTargetGroup, setSearchTargetGroup] = useState(
    params.get('targetGroup')?.split(',') || [],
  );
  const [searchLocation, setSearchLocation] = useState(
    params.get('location')?.split(',') || [],
  );
  const [searchSubject, setSearchSubject] = useState(
    params.get('categories')?.split(',') || [],
  );
  const [searchFieldOfWork, setSearchFieldOfWork] = useState(
    params.get('fieldOfWork')?.split(',') || [],
  );
  const [bStart, setBStart] = useState(0);
  const [bSize, setBSize] = useState(B_SIZE);
  const [sortOrder, setSortOrder] = useState('descending');
  const [sortOn, setSortOn] = useState('created');

  const searchResults = useSelector((state) => state.jobOfferSearch);

  const updateURLParams = (
    searchTextParam,
    searchTargetGroupParam,
    searchLocationParam,
    searchSubjectParam,
    searchFieldOfWorkParam,
  ) => {
    const paramsObj = {};

    // Preserve non search form related parameters (e.g. UTM and SEO stuff)
    for (const [key, value] of params) {
      if (!searchFormFields.includes(key)) {
        paramsObj[key] = value;
      }
    }

    if (searchTextParam?.length > 0) {
      paramsObj.text = searchTextParam;
    }
    if (searchTargetGroupParam?.length > 0) {
      paramsObj.targetGroup = searchTargetGroupParam;
    }
    if (searchLocationParam?.length > 0) {
      paramsObj.location = searchLocationParam;
    }
    if (searchSubjectParam?.length > 0) {
      paramsObj.categories = searchSubjectParam;
    }
    if (searchFieldOfWorkParam?.length > 0) {
      paramsObj.fieldOfWork = searchFieldOfWorkParam;
    }

    const queryparams = new URLSearchParams(paramsObj);
    const query = queryparams.toString();
    history.push({
      search: query,
    });
  };

  const search = () => {
    updateURLParams(
      searchText,
      searchTargetGroup,
      searchLocation,
      searchSubject,
      searchFieldOfWork,
    );
    const options = {
      searchText,
      searchTargetGroup,
      searchFieldOfWork,
      searchLocation,
      searchSubject,
      bStart,
      bSize,
      sortOn,
      sortOrder,
      pathPrefix: location.pathname.replace(/\/?$/, '/'),
    };
    dispatch(jobOfferSearchContent(location.pathname, options));
  };

  // Delay in search when typing the text input
  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchText(searchTextInput);
      resetBatching();
    }, 500);
    return () => clearTimeout(timer);
  }, [searchTextInput, searchText]);

  const resetBatching = () => {
    setBStart(0);
    setBSize(B_SIZE);
  };

  const onClickReset = useCallback(() => {
    setSearchSubject([]);
    setSearchFieldOfWork([]);
    setSearchLocation([]);
    setSearchTargetGroup([]);
    setSearchText('');
    setSearchTextInput('');
    resetBatching();
  }, []);

  const searchResultsItemsLength = searchResults?.items?.length;

  const onClickSortBy = useCallback(
    (field) => {
      if (sortOn === field) {
        setSortOrder((sortOrder) =>
          sortOrder === 'ascending' ? 'descending' : 'ascending',
        );
      } else {
        setSortOrder('ascending');
        setSortOn(field);
      }
      setBStart(0);
      setBSize(searchResultsItemsLength);
    },
    [sortOn, searchResultsItemsLength],
  );

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    searchTargetGroup,
    searchLocation,
    searchSubject,
    searchFieldOfWork,
    bStart,
    bSize,
    sortOrder,
    sortOn,
  ]);

  return (
    <Container>
      <h1>{content.title}</h1>
      {content.job_offer_container_text && (
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: content?.job_offer_container_text?.data,
          }}
        />
      )}
      <div className="job-filter">
        <Input
          onChange={({ target }) => setSearchTextInput(target.value)}
          onBlur={() => {
            setSearchText(searchTextInput);
            resetBatching();
          }}
          value={searchTextInput}
          placeholder={props.intl.formatMessage(messages.Keywordsearch)}
          aria-label="Keyword search"
        />
        <VocabularyMultiSelect
          id="job-offer-target"
          value={searchTargetGroup}
          onChange={(id, label, value) => {
            setSearchTargetGroup(value);
            resetBatching();
          }}
          placeholder={props.intl.formatMessage(messages.Targetgroup)}
          items={{
            vocabulary: {
              '@id': 'fzj.internet.vocabulary.job_offer_target_groups',
            },
          }}
        />
        <VocabularyMultiSelect
          id="job-offer-field-of-work"
          value={searchFieldOfWork}
          onChange={(id, label, value) => {
            setSearchFieldOfWork(value);
            resetBatching();
          }}
          placeholder={props.intl.formatMessage(messages.JobFoW)}
          wrapped
          items={{
            vocabulary: {
              '@id': 'fzj.internet.vocabulary.job_offer_fields_of_work',
            },
          }}
        />
        <VocabularyMultiSelect
          id="job-offer-locations"
          value={searchLocation}
          onChange={(id, label, value) => {
            setSearchLocation(value);
            resetBatching();
          }}
          placeholder={props.intl.formatMessage(messages.Location)}
          items={{
            vocabulary: {
              '@id': 'fzj.internet.vocabulary.job_offer_locations',
            },
          }}
        />
        <VocabularyMultiSelect
          id="job-offer-subjects"
          value={searchSubject}
          onChange={(id, label, value) => {
            setSearchSubject(value);
            resetBatching();
          }}
          placeholder={props.intl.formatMessage(messages.JobSubject)}
          wrapped
          items={{
            vocabulary: {
              '@id': 'fzj.internet.vocabulary.job_offer_categories',
            },
          }}
        />
      </div>
      <Container id="modified-container-job-list">
        <div className="job-list">
          <div className="listing-actions">
            <p role="heading" aria-level="1">
              {searchResults.total}{' '}
              {props.intl.formatMessage(messages.ResultsFound)}
            </p>
            <Button
              onClick={() => {
                onClickReset();
              }}
            >
              {props.intl.formatMessage(messages.ResetFilter)}
            </Button>
          </div>
          <div className="jobs-grid">
            <div className="job-list-row job-list-header mobile hidden">
              <div className="job-list-column getId">
                <Button
                  active={sortOn === 'id'}
                  onClick={() => onClickSortBy('id')}
                >
                  <Icon
                    name={
                      sortOrder === 'descending' && sortOn === 'id'
                        ? downArrowSVG
                        : upArrowSVG
                    }
                    size="24px"
                    color={sortOn === 'id' ? '#023d6b' : '#adbde3'}
                  />
                  {props.intl.formatMessage(messages.JobID)}
                </Button>
              </div>
              <div className="job-list-column title">
                <Button
                  active={sortOn === 'sortable_title'}
                  onClick={() => onClickSortBy('sortable_title')}
                >
                  <Icon
                    name={
                      sortOrder === 'descending' && sortOn === 'sortable_title'
                        ? downArrowSVG
                        : upArrowSVG
                    }
                    size="24px"
                    color={sortOn === 'sortable_title' ? '#023d6b' : '#adbde3'}
                  />
                  {props.intl.formatMessage(messages.JobTitle)}
                </Button>
              </div>
              <div className="job-list-column institute">
                <Button
                  name={
                    sortOrder === 'descending' &&
                    sortOn === 'job_offer_institute_title'
                      ? downArrowSVG
                      : upArrowSVG
                  }
                  active={sortOn === 'job_offer_institute_title'}
                  onClick={() => onClickSortBy('job_offer_institute_title')}
                >
                  <Icon
                    name={
                      sortOrder === 'descending' &&
                      sortOn === 'job_offer_institute_title'
                        ? downArrowSVG
                        : upArrowSVG
                    }
                    size="24px"
                    color={
                      sortOn === 'job_offer_institute_title'
                        ? '#023d6b'
                        : '#adbde3'
                    }
                  />
                  {props.intl.formatMessage(messages.Institut)}
                </Button>
              </div>
              <div className="job-list-column location">
                <Button
                  active={sortOn === 'job_offer_location_title'}
                  onClick={() => onClickSortBy('job_offer_location_title')}
                >
                  <Icon
                    name={
                      sortOrder === 'descending' &&
                      sortOn === 'job_offer_location_title'
                        ? downArrowSVG
                        : upArrowSVG
                    }
                    size="24px"
                    color={
                      sortOn === 'job_offer_location_title'
                        ? '#023d6b'
                        : '#adbde3'
                    }
                  />
                  {props.intl.formatMessage(messages.Location)}
                </Button>
              </div>
            </div>
            {searchResults?.loading ? (
              <Loader
                className="center-in-viewport"
                active
                indeterminate
                size="massive"
              />
            ) : (
              searchResults.items?.map((item) => (
                <div className="job-list-row" key={item['@id']}>
                  <div className="job-list-column id">{item.id}</div>
                  <div className="job-list-column title">
                    <Link to={flattenToAppURL(item['@id'])}>{item.title}</Link>
                  </div>
                  <div className="job-list-column institute">
                    {item.job_offer_institute_title}
                  </div>
                  <div className="job-list-column location">
                    {item.job_offer_location_title}
                  </div>
                </div>
              ))
            )}
          </div>
          {searchResultsItemsLength < searchResults?.total && (
            <div className="button-wrapper">
              <Button
                className="load-more"
                onClick={() => setBStart(() => searchResultsItemsLength)}
              >
                {props.intl.formatMessage(messages.MoreJobs)}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default injectIntl(JobListingView);
